.deliver-time-title {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  text-align: right;
  align-self: flex-end;
  color: $primary-blue;
}
.deliver-time-wrapper {
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 100%;
  height: 43.3px;
  border-radius: 8px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
  span.selected-time {
    @extend %fonts;
    color: $primary-blue;
  }
  span.change-time {
    @extend %fonts;
    color: $primary-pink;
  }
}
.map-img-wrapper {
  margin-top: 22px;
  cursor: pointer;
  .map-img {
    margin: 0 auto;
  }
}
