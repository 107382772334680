.handset-icons {
  width: 45px;
  height: 45px;
}

.home-icons {
  width: 32px;
  height: 32px;
}
.logo {
  width: 88px;
  height: 25.4px;
  margin: 0 auto;
}
.header-md::after {
  background-image: none !important;
}

.btncolor {
  --background: white;
  --background-activated: white;
  --padding-end: 0;
  --padding-start: 0;
}
