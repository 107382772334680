.address-details-title {
  text-align: right;
  color: $primary-blue;
  @extend %fonts;
}
.select-area-wrapper {
  width: 100%;
  margin-bottom: 13px;
  padding: 0;
  border-radius: 8px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  h2 {
    @extend %fonts;
    color: $faded-color;
  }
  .select-area-style {
    color: $primary-pink;
    width: 100%;
    height: 100%;
    background-color: transparent;
    @extend %fonts;
    border: none;
    &:focus {
      outline: none;
    }
  }
}
.text-area {
  border-radius: 8px;
  margin-bottom: 13px;
  border: 1px solid lightgray;
}

.label-floating {
  color: gray !important;
  font-size: 15px !important;
  margin-left: 7px;
}

.default-address {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fdeed3;
  padding: 8px;
  border-radius: 10px;
  width: 94%;
  margin: 0 auto;
}

.ion-focusable {
  --background: none !important;
}

.item-checkbox-checked {
  --background: none !important;
}

.find-location-btn {
  position: absolute;
  bottom: 3px;
  height: 35px;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}
