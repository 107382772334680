.gift-img {
  margin: 0 auto;
}
.message-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
  width: 100%;
  h3 {
    @extend %fonts;
    color: #333333;
    text-align: center;
  }
}
