.time-modal-content-wrapper {
  width: 100%;
  padding: 7px;
  overflow-y: scroll;
  .time-modal-header {
    @extend %select-time-modal-title;
    h2 {
      @extend %fonts;
      margin-left: 7px;
    }
    .calender-icon {
      margin-top: 7px;
    }
  }
  .selected-date-modal {
    color: $primary-blue;
    display: flex;
    white-space: pre-wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 63px;
    border-radius: 8px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #e4a993;
    background-color: #ffffff;
  }
  .select-time-title {
    @extend %select-time-modal-title;
    h2 {
      @extend %fonts;
      margin-left: 7px;
    }
    .clock-icon {
      margin-top: 7px;
    }
  }
}
ion-col {
  padding: 0;
  border-radius: 8px;
}
.dates-card-content {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $primary-pink;
  border: solid 1px #e4a993;
  border-radius: 10px;
}
.select-time-card {
  margin: 7px;
}

.choose-time-card {
  margin: 5px 0;
  .choose-time-card-content {
    padding: 12px;
  }
}
.done-buttons-wrapper {
  margin-top: 25px;
  position: fixed;
  bottom: 0;
  margin-top: 20px;
  width: 95%;
  padding-top: 100px;
}
.main-content-wrapper {
  overflow-y: scroll;
  height: 85%;
}
