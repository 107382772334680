.app-wrapper {
  align-items: center;
  justify-content: flex-start;
  // padding: 15.5px;
  overflow: scroll;
  height: auto;
  max-width: 800px;
  margin: 0 auto;
}
.header-ios ion-toolbar:last-of-type {
  --border-width: 0;
}
.item {
  --border-style: none;
}
.select-area-wrapper .select-text {
  overflow: visible !important;
}
