.time-modal-style {
  .modal-wrapper,
  .modal-shadow {
    height: 50%;
    bottom: 0;
    padding: 10%;
    position: absolute;
    display: block;
    border-radius: 20px 20px 0 0;
  }
}

.ion-page > div {
  width: 100%;
  height: 100%;
}

.map-modal-content-wrapper {
  height: 100%;
  position: relative;
}

.confirm-wrapper {
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
  h2 {
    @extend %fonts;
  }
}

.call-center-modal-wrapper {
  padding: 10px;
  h2 {
    @extend %fonts;
    font-size: 20px;
    color: $primary-blue;
  }
  h6 {
    font-size: 18px;
    font-weight: normal;
    line-height: 1.39;
  }
  .call-center-links {
    margin-left: 15px;
    color: $black;
    text-decoration-line: none;
    font-size: 18px;
    font-weight: 500;
  }
}

.marker-style-center {
  position: absolute;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */

  transform: translate(-50%, -50%);
}
.map-auto-complete {
  height: 40px;
  padding-left: 40px;
  position: absolute;
  top: 20px;
  right: 11px;
  width: 95%;
  background-color: white;
  color: black;
  border: 0.1px solid;
  border-radius: 5px;
  outline-color: $primary-blue;
}

#suggestComponent {
  width: 90%;
  margin: 0 auto;
  // border: 0.1px solid;
  border-radius: 5px;
  outline-color: $primary-blue;
  margin-bottom: 20px;
}

.what3words-autosuggest .options {
  background-color: white !important;
  opacity: 1 !important;
  z-index: 3000 !important;
}

.options-wrap {
  background-color: white !important;
  opacity: 1 !important;
  z-index: 3000 !important;
  .options-item {
    opacity: 1 !important;
    z-index: 3000 !important;
  }
}
