.thank-message-wrapper {
  .thank-message-title {
    @extend %fonts;
    font-size: 32px;
    color: $primary-blue;
    text-align: center;
    margin: 0 auto;
  }
  h3 {
    @extend %fonts;
    font-size: 16px;
    text-align: center;
    color: $black;
  }
  h4 {
    @extend %fonts;
    font-size: 14px;
    text-align: center;
    color: $faded-color;
  }
}

.btncolor {
  --background: white;
  --background-activated: white;
}
